/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player/lazy";
import { isMobile } from "react-device-detect";
// import ModalImage, { Lightbox } from "react-modal-image";
import Modal from "react-modal";
import LOGO from "../../assets/hey-wizard-logo.png";
import USER from "../../assets/demo-user.png";

import api from "utils/api";
import axios from "axios";
import { TiSocialFacebook, TiSocialLinkedin } from "react-icons/ti";
import { SlSocialInstagram } from "react-icons/sl";
import {
  FaHandshakeAngle,
  FaCircleUser,
  FaLink,
  FaXTwitter,
} from "react-icons/fa6";
import { BiSolidMessageRounded } from "react-icons/bi";
import {
  MdDownload,
  MdVideoCall,
  // MdOutlineKeyboardDoubleArrowUp,
  MdOutlineFolderCopy,
  MdOutgoingMail,
} from "react-icons/md";
import { MdOutlineQrCodeScanner, MdLocationOn , MdArrowBack} from "react-icons/md";
import { HiDownload } from "react-icons/hi";
import { ImSphere } from "react-icons/im";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { RiGraduationCapFill } from "react-icons/ri";
import { IoShareSocial, IoChevronBackCircleSharp } from "react-icons/io5";

import PrefixButton from "components/PrefixButton";
import WebLink from "components/WebLink";

import "./userinfo.css";
import QRCode from "react-qr-code";
import jsPDF from "jspdf";
import HookFormExchange from "components/HookFormExchange";

const ExpertNewProfile = () => {
  const { slug: userName } = useParams();
  const [userData, setUserData] = useState([]);
  const [weblinks, setWebLinks] = useState([]);
  const [openQrModal, setOpenQrModal] = useState(false);
  const [iseLoading, setIsLoading] = useState(true);
  const [openInAppLink, setOpenInAppLink] = useState("https://heywizard.app/");
  const [openSection, setOpenSection] = useState("");
  const [isProfile, setIsProfile] = useState(null);
  // const [isMobile, setIsMobile] = useState(false);
  const [error,setError] = useState({
    isError: false,
    message: ""
  })

  const handlePlayClick = () => {
    // If video is not available, show an alert
    if (!userData?.video || userData?.video.length === 0) {
      alert("Video is not available");
    }
  };

  // const GET_EXPERT_PROFILE = process.env.REACT_APP_GET_EXPERT_PROFILE;
  // useEffect(() => {
  //   // Check device type on mount
  //   setIsMobile(isMobileDevice());

  //   // Listen for media query changes to dynamically update the device type
  //   const handleMediaChange = (e) => {
  //     setIsMobile(e.matches); // e.matches is true if the media query matches (mobile)
  //   };

  //   // Media query listener
  //   const mediaQuery = window.matchMedia("(max-width: 768px)");
  //   mediaQuery.addEventListener("change", handleMediaChange);

  //   // Cleanup listener on unmount
  //   return () => {
  //     mediaQuery.removeEventListener("change", handleMediaChange);
  //   };
  // }, []);
  useEffect(() => {
    api
      .getExpertData(userName)
      .then((response) => {
        setUserData(response.data.InterstedExpertez);
        const data = response.data.InterstedExpertez;
        const weblinkArr = Object.keys(data)
          .filter(
            (key) =>
              key.startsWith("web_link") && data[key] && !key.endsWith("_label") && !key.endsWith("_icon")
          )
          .map((link) => {
            const lable = `${link}_label`;
            const icon = `${link}_icon`;
            return {
              link: data[link],
              key: data[lable],
              icon:data[icon]
            };
          });
        setWebLinks(weblinkArr);
      })
      .catch((err) => {
        setError({
          isError: true,
          message: err.response.data.message,
        });
        
      }).finally(()=>{
        setIsLoading(false);
      })
  }, [userName]);

  useEffect(() => {
    const getOpenInAppLink = async () => {
      const apiUrl =
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCxgDSxaLjtqGLZx19CnrtT4CXA02Rbwk4";

      const requestData = {
        dynamicLinkInfo: {
          domainUriPrefix: "https://heywizard.page.link",
          link: `${process.env.REACT_APP_FIREBASE_NFC_URL}/expert-profile/${userName}/BrowseSingleExpertLatest`,
          androidInfo: {
            androidPackageName: "com.expertez.customer",
          },
          iosInfo: {
            iosBundleId: "heywizard.customer",
            iosAppStoreId: "1610750220",
          },
        },
      };

      await axios
        .post(apiUrl, requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          let responseLink = response.data.shortLink;
          if (responseLink) {
            if (isMobile) {
              setOpenInAppLink(responseLink);
            } else {
              setOpenInAppLink("https://heywizard.app/");
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    getOpenInAppLink();
  }, [userName]);

  const handleClick = (name) => {
    setOpenSection(openSection === name ? "" : name);
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${userData.email}`;
  };

  if (iseLoading) {
    return (
      <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
        <div className="border-t-transparent border-solid animate-spin  rounded-full border-purple-700 border-8 h-52 w-52"></div>
      </div>
    );
  }

  const generatePDF = (document) => {
    const doc = new jsPDF();

    // Add document details to the PDF
    doc.setFont("helvetica", "normal");
    doc.text("Document Details", 10, 10);
    doc.text(`Name: ${document.name}`, 10, 20);
    doc.text(`Size: ${document.size}`, 10, 30);
    doc.text(`Link: ${document.path}`, 10, 40);

    // Save the PDF with the document name
    doc.save(`${document.name.replace(/\.pdf$/, "")}.pdf`);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const baseUrl = "https://dev.heywizard.app/api/"; // Adjust this for live API
  const imageUrl = `${baseUrl.split("api/")[0]}storage/uploads/file/`;

  if(error.isError){
    return (
      <div className="flex items-center justify-center">
        <div className="text-center">
        {error.message}
        </div>
      </div>
    )
  }
  return (
    <div className="bg-gray-100">
      <div className="px-8 pt-2 h-16 -mb-5 flex justify-between bg-white">
        <a href="https://heywizard.app/">
          <img src={userData?.is_subscribed ? userData?.expert_logo : LOGO} className="h-14" alt="hey-wizard-logo" />
        </a>
        <span className="flex items-center" aria-label="avatar">
          <a href={openInAppLink}>
            <img
              src={`${
                userData?.profile_image?.image_name
                  ? userData?.profile_image?.image_name
                  : USER
              }`}
              alt=""
              className=" w-8 h-8 rounded-full  shrink-0"
            />
          </a>
        </span>
      </div>
      {!isProfile && <div className="container mx-auto py-8 max-w-md !text-white profile-selector">
        <div className="flex flex-col justify-center flex-1 px-4 py-8 gap-4 rounded-lg shadow-lg bg-white">
        <PrefixButton className="p-3 rounded-3xl bg-[#52aebd]" onClick={()=>setIsProfile("profile")}>View profile</PrefixButton>
        <PrefixButton className="p-3 rounded-3xl bg-[#52aebd]" onClick={()=>setIsProfile("exchange")}>Contact form</PrefixButton>
          </div>
        </div>}
      {isProfile==="profile" && (
        <div className="container mx-auto py-8 !text-white max-w-md bg-white">
          <div className="p-3">

          <MdArrowBack fontSize={22} color="black" className="cursor-pointer" onClick={()=>setIsProfile(null)}/>
          </div>
          {/*profile image */}
          <div className="relative px-3">
            <div className="h-[400px] w-full rounded-2xl bg-[#52aebd] border-4 border-white shadow-lg overflow-hidden">
              {/* Video player or Play button */}
              {userData?.video && userData?.video.length !== 0 ? (
                <video
                  className="w-full h-full object-cover"
                  controls
                  autoPlay
                  src={userData?.video[0]?.video_name}
                ></video>
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-gray-200">
                  {/* Play button */}
                  <button
                    onClick={handlePlayClick}
                    className="text-white text-xl flex items-center justify-center"
                  >
                    <span
                      role="img"
                      aria-label="play-button"
                      className="text-4xl"
                    >
                      ▶️
                    </span>
                  </button>
                </div>
              )}
            </div>
            <div className="flex items-center justify-start my-6 gap-4">
              {/* Profile image container */}
              <div className="h-20 w-20 rounded-full bg-[#52aebd] border-4 border-white relative overflow-hidden">
                <img
                  src={userData?.profile_image?.image_name || USER}
                  alt="profile"
                  className="h-full w-full object-cover"
                />
              </div>

              {/* User information */}
              <div className="text-black flex-1">
                {/* Full name */}
                <div className="font-bold text-xl mb-1">
                  {userData?.full_name}
                </div>

                {/* About text */}
                <div className="text-sm text-gray-600">
                  {userData?.job_title}
                </div>
                <div className="text-sm text-gray-600">
                  {userData?.work_place}
                </div>
              </div>
            </div>
          </div>

          {/* qr, save and connect */}
          <div className="flex items-center gap-2 px-3 mt-2">
            {/* <button className="flex-1 p-2 rounded-3xl bg-[#52aebd]">Save Contact</button> */}
            <PrefixButton
              className="flex-1 p-2 rounded-3xl bg-[#52aebd]"
              isConnectORSaveORMessageORSchedule="isConnectORSaveORMessageORSchedule"
              isMobile={isMobile}
            >
              {
                <a
                  href={`tel:${userData?.phone}`}
                  className="flex items-center"
                >
                  <MdDownload color="white" fontSize={20} /> &nbsp;Save Contact
                </a>
              }
            </PrefixButton>
            <div
              onClick={() => setOpenQrModal(true)}
              className="cursor-pointer"
            >
              <MdOutlineQrCodeScanner fontSize={25} color="black" />
            </div>
            <PrefixButton
              isMobile={isMobile}
              isConnectORSaveORMessageORSchedule="isConnectORSaveORMessageORSchedule"
              className="flex-1 p-2 rounded-3xl bg-[#52aebd]"
              onClick={() => window.open(openInAppLink, "_blank")}
            >
              <FaHandshakeAngle color="white" fontSize={20} />
              &nbsp;Connect
            </PrefixButton>
          </div>

          {/* locaton */}
          <div className="mt-5 flex items-center  px-3">
            <div className="flex-1 border-t border-black"></div>
            {userData?.business_address && (
              <div className="text-black flex items-center px-1">
                <MdLocationOn fontSize={20} />
                &nbsp; <span>{userData?.business_address || ""}</span>
              </div>
            )}
            <div className="flex-1 border-t border-black"></div>
          </div>

          {/* social links */}
          <div className=" flex justify-center items-center gap-2 mt-5">
            {userData?.facebook && (
              <a
                className="w-10 h-10 shadow-xl rounded-full  flex items-center justify-center text-black border border-gray bg-[#4848bd]"
                href={userData?.facebook}
                target="_blank"
              >
                <TiSocialFacebook color="white" fontSize={23} />
              </a>
            )}
            {userData?.linkedin && (
              <a
                className="w-10 h-10 shadow-xl rounded-full  flex items-center justify-center text-black border border-gray bg-[#0077B5]"
                href={userData?.linkedin}
                target="_blank"
              >
                <TiSocialLinkedin color="white" fontSize={24} />
              </a>
            )}
            {userData.twitter && (
              <a
                className="w-10 h-10 shadow-xl rounded-full  flex items-center justify-center  text-black border border-gray bg-[#1da1f2]"
                href={userData?.twiter}
                target="_blank"
              >
                <FaXTwitter color="white" fontSize={24} />
              </a>
            )}
            {userData.instagram && (
              <a
                className="w-10 h-10 shadow-xl rounded-full flex items-center justify-center text-black border border-gray bg-[#d72f44]"
                href={userData?.instagram || "https://www.instagram.com/"}
                target="_blank"
              >
                <SlSocialInstagram color="white" />
              </a>
            )}
          </div>

          {/* message and call      */}
          <div className="flex items-center gap-2 px-12 mt-5">
            <PrefixButton
              isMobile={isMobile}
              isConnectORSaveORMessageORSchedule="isConnectORSaveORMessageORSchedule"
              className="flex-1 p-2 rounded-3xl bg-[#52aebd]"
              onClick={() => window.open(openInAppLink, "_blank")}
            >
              <BiSolidMessageRounded fontSize={22} />
              &nbsp; Message
            </PrefixButton>
            {/* <button className="flex-1 p-2 rounded-3xl bg-[#52aebd]">message</button> */}

            <PrefixButton
              isMobile={isMobile}
              isConnectORSaveORMessageORSchedule="isConnectORSaveORMessageORSchedule"
              className="flex-1 p-2 rounded-3xl bg-[#52aebd]"
              onClick={() => window.open(openInAppLink, "_blank")}
            >
              {" "}
              <MdVideoCall fontSize={22} />
              &nbsp;Schedule a call
            </PrefixButton>
          </div>

          {/* circle */}
          {/* <div className="w-12 h-12 shadow-xl rounded-full m-auto flex items-center justify-center mt-5 text-black border border-[#d7d7d7]">
            <MdOutlineKeyboardDoubleArrowUp fontSize={25}/>
        </div> */}

          {/* links */}
          <div className="flex flex-col gap-5 mt-6 px-3">
            {/* <div><button className="">WEB LINKS</button></div> */}
            <div>
              <PrefixButton
                className="flex-1 px-2 py-3 rounded-3xl bg-[#52aebd] w-full"
                prefixIcon={<MdOutgoingMail fontSize={25} />}
                onClick={handleEmailClick}
              >
                Email
              </PrefixButton>
            </div>
            <div>
              <PrefixButton
                className="flex-1 px-2 py-3 rounded-3xl bg-[#52aebd] w-full"
                prefixIcon={<ImSphere fontSize={23} />}
                onClick={() => handleClick("web-link")}
              >
                WEB LINKS
              </PrefixButton>
              {openSection === "web-link" && (
                <div className="flex flex-col gap-2 mt-3 text-black web-link-container">
                  {weblinks?.map((item) => (
                    <WebLink
                      key={item.link}
                      className="flex-1 px-2 py-2 rounded-3xl w-full shadow-md font-medium"
                      prefixIcon={
                        <FaLink
                          fontSize={25}
                          color="white"
                          className="bg-black rounded-full p-1"
                        />
                      }
                      postfixIcon={
                        <IoShareSocial
                          fontSize={25}
                          color="white"
                          className="bg-purple-500 rounded-full p-1"
                        />
                      }
                      link={item.link}
                      icon={item.icon}
                    >
                      {item.key}
                    </WebLink>
                  ))}
                  {/* <WebLink
                  className="flex-1 px-2 py-2 rounded-3xl w-full shadow-md font-medium"
                  prefixIcon={
                    <IoShareSocial
                      fontSize={25}
                      color="white"
                      className="bg-purple-500 rounded-full p-1"
                    />
                  }
                >
                  Share this weblinks
                </WebLink> */}
                </div>
              )}
            </div>
            <div>
              <PrefixButton
                className="flex-1 px-2 py-3 rounded-3xl bg-[#52aebd] w-full"
                prefixIcon={<HiOutlineDocumentText fontSize={23} />}
                onClick={() => handleClick("document")}
              >
                DOCUMENT
              </PrefixButton>
              {openSection === "document" &&
                userData?.documents?.length > 0 && (
                  <div className="w-full m-auto mt-3">
                    {userData?.documents?.map((document) => (
                      <div
                        key={document.id}
                        className="flex gap-2 items-center px-2 py-1 rounded-xl w-5/6 m-auto cursor-pointer text-black border border-black mt-3"
                      >
                        <div>
                          <HiOutlineDocumentText
                            fontSize={32}
                            fontWeight={900}
                          />
                        </div>
                        <div>
                          <p className="text-sm truncate" name={document.name}>
                            {truncateText(document.name, 30)}
                          </p>
                          <p className="text-sm">{document.size}</p>
                        </div>
                        <button
                          onClick={() => generatePDF(document)}
                          className="px-4 py-2 bg-green-500 text-white rounded-lg"
                        >
                          <HiDownload className="text-white " fontSize={18} />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
            </div>
            <div>
              <PrefixButton
                className="flex-1 px-2 py-3 rounded-3xl bg-[#52aebd] w-full"
                prefixIcon={<MdOutlineFolderCopy fontSize={23} />}
                onClick={() => handleClick("portfolio")}
              >
                PORTFOLIO
              </PrefixButton>
              {openSection === "portfolio" &&
                userData?.portfolio_image?.[0] && (
                  <div className="grid grid-cols-3 gap-2 mt-3">
                      {userData?.portfolio_image.map(item=>(<div key={item.image_name} className="h-36 overflow-hidden rounded-md"><img src={item.image_name} className="h-full w-full object-cover"/></div>))}
                    </div>
                  // <div className="flex mt-3">
                  //   <div className="mx-auto w-52 h-40">
                  //     <img
                  //       src={userData?.portfolio_image?.[0]?.image_name}
                  //       alt="profile"
                  //       className="object-cover w-full h-full"
                  //     ></img>
                  //   </div>
                  // </div>
                )}
            </div>
            <div>
              <PrefixButton
                className="flex-1 px-2 py-3 rounded-3xl bg-[#52aebd] w-full"
                prefixIcon={<RiGraduationCapFill fontSize={23} />}
                onClick={() => handleClick("qualification")}
              >
                QUALIFICATION
              </PrefixButton>

              {openSection === "qualification" && (
                <div className="mt-6 p-6 bg-white border border-gray-300 rounded-xl w-5/6 mx-auto shadow-lg">
                  {userData?.qualifictaions?.length > 0 ? (
                    userData?.qualifictaions?.map((qualification) => (
                      <div
                        key={qualification.id}
                        className="mb-6 p-6 border-l-4 border-[#1da1f2] bg-gray-50 rounded-lg shadow-sm"
                      >
                        <p className=" text-[#333] mb-2">
                          <strong>Degree:</strong> {qualification.degree}
                        </p>
                        <p className="text-sm text-gray-700 mb-2">
                          <strong>School/College Name:</strong>{" "}
                          {qualification.school_college_name}
                        </p>
                        <p className="text-sm text-gray-700 mb-2">
                          <strong>From:</strong> {qualification.start_year}{" "}
                          <strong>To:</strong> {qualification.end_year}
                        </p>

                        {/* Certificate Image */}
                        {qualification?.certificate_name && (
                          <div className="">
                            <strong className="text-sm text-gray-800">
                              Certificate:
                            </strong>
                            <div className="mt-2">
                              <img
                                src={`${imageUrl}${qualification?.certificate_name}`} // Use the constructed URL
                                alt="Certificate"
                                className="w-48 h-48 object-cover rounded-lg shadow-md"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <p className="text-center text-gray-500">
                      No qualifications found
                    </p>
                  )}
                </div>
              )}
            </div>
            <div>
              <PrefixButton
                className="flex-1 px-2 py-3 rounded-3xl bg-[#52aebd] w-full"
                prefixIcon={<FaCircleUser fontSize={23} />}
                onClick={() => handleClick("bio")}
              >
                BIO
              </PrefixButton>
              {openSection === "bio" && (
                <div className="flex-1 px-4 py-3 rounded-3xl w-full shadow-md text-black bg-white mt-3">
                  {userData?.about_yourself}
                </div>
              )}
            </div>
            {/* <div><button className="flex-1 p-2 rounded-3xl bg-[#52aebd]">WEB LINKS</button></div>
             <div><button className="flex-1 p-2 rounded-3xl bg-[#52aebd]">WEB LINKS</button></div>
             <div><button className="flex-1 p-2 rounded-3xl bg-[#52aebd]">WEB LINKS</button></div> */}
          </div>
        </div>
      )}
      <Modal
        isOpen={openQrModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setOpenQrModal(false)}
        overlayClassName="modal-overlay"
        className="modal-body-container"
        // style={{
        //   top: '50%',
        //   left: '50%',
        //   transform: 'translate(-50%, -50%)',
        // }}
        contentLabel="Example Modal"
      >
        <QRCode value={window.location.href} />
      </Modal>
      <Modal
        isOpen={isProfile==="exchange"}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setOpenQrModal(false)}
        className="modal-body-container py-0  rounded-md min-h-80 max-h-[90dvh] shadow-md overflow-auto w-4/5 px-3 w-[90vw] md:max-w-[600px] md:px-5"
        // style={{
        //   top: '50%',
        //   left: '50%',
        //   transform: 'translate(-50%, -50%)',
        // }}
        contentLabel="Example Modal"
      >
       {isProfile==="exchange" &&  <HookFormExchange setIsProfile={setIsProfile}/>}
      </Modal>
      {/* <Lightbox large={<QRCode value="hey" />}>
      
      </Lightbox> */}
    </div>
  );
};

export default ExpertNewProfile;
