/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import {isMobile} from 'react-device-detect';
import ModalImage from "react-modal-image";
import LOGO from '../../assets/hey-wizard-logo.png'
import USER from '../../assets/demo-user.png'

import api from "utils/api";
import axios from "axios";
const ExpertProfile = () => {
  const { slug } = useParams();
  const [userData, setUserData] = useState([]);
  const [iseLoading, setIsLoading] = useState(true);
  const [openInAppLink, setOpenInAppLink] = useState("https://heywizard.app/");
  const GET_EXPERT_PROFILE = process.env.REACT_APP_GET_EXPERT_PROFILE;
  useEffect(() => {
    api
      .getExpertData(slug)
      .then((response) => {
        console.log("response",response)
        setUserData(response.data.InterstedExpertez);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // console.log("err",err)
        setIsLoading(false);
      });
  }, [slug]);





  useEffect(() => {
    const getOpenInAppLink = async() => {
      const apiUrl =
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCxgDSxaLjtqGLZx19CnrtT4CXA02Rbwk4";
  
      const requestData = {
        dynamicLinkInfo: {
          domainUriPrefix: "https://heywizard.page.link",
          link: `${process.env.REACT_APP_FIREBASE_NFC_URL}/expert-profile/${slug}/BrowseSingleExpertLatest`,
          androidInfo: {
            androidPackageName: "com.expertez.customer",
          },
          iosInfo: {
            iosBundleId: "heywizard.customer",
            iosAppStoreId: "1610750220"
          },
        },
      };
  
    await axios
        .post(apiUrl, requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
         let responseLink = response.data.shortLink
          if(responseLink){
            if(isMobile){
              setOpenInAppLink(responseLink)
            }else{
              setOpenInAppLink('https://heywizard.app/')
            }
          }
         
          
          
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    getOpenInAppLink()
  }, [slug]);

  if (iseLoading) {
    return (
      <div class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
        <div class="border-t-transparent border-solid animate-spin  rounded-full border-purple-700 border-8 h-52 w-52"></div>
      </div>
    );
  }
  return (
    <div className="bg-gray-100">
      <div className="px-8 pt-2 h-16 -mb-5 flex justify-between bg-white">
      <a href='https://heywizard.app/'><img src={LOGO} className="h-14" alt="hey-wizard-logo"/></a>
        <span className="flex items-center" aria-label="avatar">
        <a href={openInAppLink}><img src={`${userData?.profile_image?.image_name ? userData?.profile_image?.image_name:USER}`} alt="" className=" w-8 h-8 rounded-full  shrink-0" /></a>
      </span>
      </div>
      <div className="container mx-auto py-8">
        <div className="grid grid-cols-4 sm:grid-cols-12 gap-6 px-4">
          <div className="col-span-4 sm:col-span-3">
            <div className="bg-white shadow rounded-lg p-6">
              <div className="flex flex-col items-center">
                {/* <img
                  src={userData?.profile_image?.image_name}
                  className="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0"
                  alt="User Profile"
                /> */}
                 <ModalImage
                     small={`${userData?.profile_image?.image_name ? userData?.profile_image?.image_name:USER}`}
                      large={`${userData?.profile_image?.image_name ? userData?.profile_image?.image_name:USER}`}
                      className="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0"
                      hideDownload
                        // alt="Hello World!"
                            />
                
                <h1 className="text-xl font-bold">{userData?.full_name}</h1>
                <p className="text-gray-600">
                  {userData?.designation || "designation"}
                </p>
                

                <div className="mt-6 flex flex-wrap gap-4 justify-center">
                
                  <a
                    href={openInAppLink}
                    className="bg-teal-400 hover:bg-teal-500 text-white py-2 px-4 rounded"
                  >
                    Open In App
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 sm:col-span-9">
            <div className="bg-white  p-2">
              <div className="flex flex-col bg-gray-100 py-8 px-6">
                <div className="bg-white rounded-lg p-6  shadow-lg grid grid-cols-1 md:grid-cols-2 items-center justify-around">
                  <div className="grid gap-5">
                    <span className="text-gray-600 uppercase font-bold tracking-wider mb-2">
                      Web Links
                    </span>
                    <ul className="text-xs md:text-lg">
                      <li className="mb-2 underline">
                        <a href={userData?.web_link_1}>
                          {userData?.web_link_1_label ? userData?.web_link_1_label : ""}
                        </a>
                      </li>
                      <li className="mb-2 underline">
                        <a href={userData?.web_link_2}>
                          {userData?.web_link_2_label ? userData?.web_link_2_label : ""}
                        </a>
                      </li>
                      <li className="mb-2 underline">
                        <a href={userData?.web_link_3}>
                          {userData?.web_link_3_label ? userData?.web_link_3_label  : ""}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="py-8 px-6">
                    <div className="bg-white rounded-lg p-6 flex justify-center items-center flex-row md:flex-row gap-6 my-6">
                    { userData?.linkedin && <a
                        className="text-gray-700 hover:text-blue-800"
                        aria-label="Visit TrendyMinds LinkedIn"
                        href={userData?.linkedin}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          className="h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                          ></path>
                        </svg>
                      </a>}
                   { userData?.facebook &&  <a
                        className="text-gray-700 hover:text-blue-600"
                        aria-label="Visit TrendyMinds Facebook"
                        href={userData?.facebook}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          className="h-6"
                        >
                          <path
                            fill="currentColor"
                            d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                          ></path>
                        </svg>
                      </a>}
                     {userData?.instagram &&  <a
                        className="text-gray-700 hover:text-red-600"
                        aria-label="Visit TrendyMinds Instagram"
                        href={userData?.instagram}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          className="h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7-22.9 42.6-42.6 11.7-29.5 9-99.5 9-132.1s2.7 102.7-9 132.1z"
                          ></path>
                        </svg>
                      </a>}
                      {userData?.twiter && <a
                        className="text-gray-700 hover:text-blue-400"
                        aria-label="Visit TrendyMinds Twitter"
                        href={userData?.twiter}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                          ></path>
                        </svg>
                      </a>}
                    </div>
                  </div>
                </div>
              </div>
              {userData?.video && userData?.video.length !== 0 && (
                <div className="bg-gray-100 py-8 px-6 ">
                  <h3 className="text-2xl font-semibold text-center  mb-2">
                    Videos
                  </h3>
                  <div className="bg-white rounded-lg p-6 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 shadow-lg place-items-center gap-4 ">
                    {userData?.video?.map((v) => (
                      <ReactPlayer
                        url={v?.video_name}
                        // url='https://www.youtube.com/watch?v=4VaqA-5aQTM'
                        width={isMobile?"250px":"300px"}
                        height={isMobile?"140px":"170px"}
                        controls={true}
                        className="border border-2"
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className="bg-gray-100 py-8 px-6">
                <h2 className="text-2xl font-semibold mb-4 text-center">BIO</h2>
                <div className="bg-white rounded-lg p-6 shadow-lg">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2 sm:col-span-2">
                      <div className="text-gray-700 grid grid-cols-1 md:grid-cols-2 ">
                        <p className="mb-2">
                          <span className="font-bold">Name:</span>{" "}
                          {userData?.full_name}
                        </p>
                        <p className="mb-2">
                          <span className="font-bold">Phone No.:</span>{" "}
                          {userData?.phone}
                        </p>
                        <p className="mb-2">
                          <span className="font-bold">E-mail:</span>{" "}
                          {userData?.email}
                        </p>
                        <p className="mb-2">
                          <span className="font-bold">Date Of Birth:</span>{" "}
                          {userData?.dob}
                        </p>
                        {/* <p className="mb-2">
                          <span className="font-bold">Gender:</span>{" "}
                          {userData?.gender}
                        </p> */}
                      </div>
                        <div className="mb-2 flex gap-2">
                          <span className="font-bold text-gray-700">Images:</span>
                          <div className=" gap-4 p-2 flex flex-wrap">
                            {userData?.image?.map((i) => {
                              return (
                                // <img
                                //   src={i.image_name}
                                //   alt=""
                                //   className="w-16"
                                // />
                                <ModalImage
                                        small={i.image_name}
                                      large={i.image_name}
                                      // alt="Hello World!"
                                      hideDownload
                                      className="w-36 h-72"
                                    />
                              );
                            })}
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              {userData?.qualifictaions && (
                <div className="bg-gray-100 py-8 px-6">
                  <h2 className="text-2xl font-semibold mb-4 text-center">
                    Qualifications
                  </h2>
                  <div className="bg-white rounded-lg p-6 shadow-lg ">
                    {userData?.qualifictaions?.map((qualification) => (
                      <div
                        key={qualification.id}
                        className="mb-4 grid grid-cols-1 md:grid-cols-2"
                      >
                        <p className="text-gray-700">
                          <span className="font-bold">Degree:</span>{" "}
                          {qualification.degree}
                        </p>
                        <p className="text-gray-700">
                          <span className="font-bold">School/College:</span>{" "}
                          {qualification.school_college_name}
                        </p>
                        <p className="text-gray-600">
                          <span className="font-bold">Duration:</span>{" "}
                          {qualification.start_year} - {qualification.end_year}
                        </p>
                        <p className="text-gray-700">
                          <span className="font-bold">Area of Study:</span>{" "}
                          {qualification.area_of_study}
                        </p>
                        <p className="text-gray-700">
                          <span className="font-bold">Description:</span>{" "}
                          {qualification.description}
                        </p>
                       {qualification?.certificate_name && <p className="text-gray-700">
                          <span className="font-bold">Certificate:</span>{" "}
                          {/* <img src={`${GET_EXPERT_PROFILE.split('api/')[0]}storage/uploads/file/${qualification?.certificate_name}`} alt='' /> */}
                          <ModalImage
                               small={`${GET_EXPERT_PROFILE.split('api/')[0]}storage/uploads/file/${qualification?.certificate_name}`}
                              large={`${GET_EXPERT_PROFILE.split('api/')[0]}storage/uploads/file/${qualification?.certificate_name}`}
                              hideDownload
                            // alt="Hello World!"
                          />
                        </p>}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {userData?.portfolio_image?.length > 0 && (
                <div className="bg-gray-100 py-8 px-6 ">
                  <h2 className="text-2xl font-semibold mb-4 text-center">
                    Portfolio
                  </h2>
                  <div className="bg-white rounded-lg p-6 grid grid-cols-1 md:grid-cols-2 shadow-lg">
                    {userData?.portfolio_image?.map((image) => {
                      // return <img src={image.image_name} alt="" />;
                      return  <ModalImage
                               small={image.image_name}
                              large={image.image_name}
                              hideDownload
                       // alt="Hello World!"
                          />
                    })}
                  </div>
                </div>
              )}

              {/* <h2 className="text-xl font-bold mt-6 mb-4">Experience</h2>
              <div className="mb-6">
                <div className="flex justify-between">
                  <span className="text-gray-600 font-bold">Web Developer</span>
                  <p>
                    <span className="text-gray-600 mr-2">at ABC Company</span>
                    <span className="text-gray-600">2017 - 2019</span>
                  </p>
                </div>
                <p className="mt-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  finibus est vitae tortor ullamcorper, ut vestibulum velit
                  convallis. Aenean posuere risus non velit egestas suscipit.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertProfile;
